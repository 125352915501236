/**
 * Service for user authentication
 *
 * @class AuthService
 */
class AuthService {
  /**
   * Login the user with the given credentials.
   *
   * @param {object} credentials - User credentials
   * @param {string} credentials.username - User username
   * @param {string} credentials.password - User password
   * @return {Promise<object>} Promise that resolves to the user and token
   */
  async login (credentials) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
      console.log('Response status:', response.status)

      if (response.ok && response.headers.get('Content-Type')?.includes('application/json')) {
        const data = await response.json()
        console.log('Response data:', data)
        sessionStorage.setItem('user', JSON.stringify(data.user))
        sessionStorage.setItem('token', data.token)
        return { user: data.user, token: data.token }
      } else {
        const errorText = await response.text()
        console.error('Login failed:', errorText)
        throw new Error(errorText || 'Login failed')
      }
    } catch (error) {
      console.error('Login error:', error)
      throw error
    }
  }

  /**
   * Logout the current user.
   */
  logout () {
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('token')
  }

  /**
   * Check if the user is authenticated.
   *
   * @return {boolean} True if the user is authenticated, false otherwise
   */
  isAuthenticated () {
    const user = sessionStorage.getItem('user')
    return !!user
  }

  /**
   * Get the current user.
   *
   * @return {object} User object
   */
  getCurrentUser () {
    const userData = sessionStorage.getItem('user')
    return userData ? JSON.parse(userData) : null
  }

  /**
   * Get the current user's token.
   *
   * @return {string} User token
   */
  getToken () {
    return sessionStorage.getItem('token')
  }

  /**
   * Delete the current user's account.
   *
   * @param {string} userId - User ID
   */
  async deleteAccount (userId) {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
    try {
      const token = this.getToken()
      const response = await fetch(`${API_BASE_URL}/api/users/${userId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error('Account deletion failed:', errorText)
        throw new Error(errorText || 'Failed to delete account')
      }

      // If successful, log out the user
      this.logout()
      console.log('Account successfully deleted')
    } catch (error) {
      console.error('Delete account error:', error)
      throw error
    }
  }
}

export default new AuthService()
