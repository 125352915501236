import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './Login.css'

/**
 * Login component to display the login page.
 *
 * @return {JSX.Element} JSX element
 */
function Login () {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { login } = useAuth()

  /**
   * Handle login button click event.
   */
  const handleLogin = async () => {
    try {
      const success = await login({ username, password })
      if (success) {
        navigate('/dashboard')
      } else {
        alert('Login failed: Incorrect credentials')
      }
    } catch (error) {
      alert('Login failed: ' + error.message)
    }
  }

  return (
    <div className="Login">
      <Header />
      <div className="login-container">
        <h1>Login</h1>
        <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
        <button onClick={handleLogin} className="login-button">Login</button>
      </div>
      <Footer />
    </div>
  )
}

export default Login
