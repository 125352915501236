import { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import { useAuth } from '../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import './Dashboard.css'

/**
 * Dashboard component to display user's active and past bookings.
 *
 * @return {JSX.Element} JSX element
 */
function Dashboard () {
  const { currentUser, getToken } = useAuth()
  const [bookings, setBookings] = useState([])
  const [venues, setVenues] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [currentUser, navigate])

  useEffect(() => {
    /**
     * Fetch user's bookings and venues from the API.
     */
    const fetchBookingsAndVenues = async () => {
      if (!currentUser || !currentUser.id) {
        console.error('User is not defined or does not have an id:', currentUser)
        return
      }

      try {
        const token = await getToken()

        // Fetch user's bookings
        const bookingsResponse = await fetch(`/api/bookings/user/${currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (!bookingsResponse.ok) {
          throw new Error('Failed to fetch bookings')
        }
        const bookingsData = await bookingsResponse.json()

        // Separate bookings into active and past
        const now = new Date()
        const activeBookings = bookingsData.filter(booking => new Date(booking.timeSlot.end) >= now)
        const pastBookings = bookingsData.filter(booking => new Date(booking.timeSlot.end) < now)

        // Sort bookings by start time, closest date first
        activeBookings.sort((a, b) => new Date(a.timeSlot.start) - new Date(b.timeSlot.start))
        pastBookings.sort((a, b) => new Date(a.timeSlot.start) - new Date(b.timeSlot.start))

        setBookings({ active: activeBookings, past: pastBookings })

        const venuesResponse = await fetch(`/api/venues/user/${currentUser.id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (!venuesResponse.ok) {
          throw new Error('Failed to fetch venues')
        }
        const venuesData = await venuesResponse.json()
        setVenues(venuesData)
      } catch (error) {
        console.error('Error fetching bookings or venues:', error)
      }
    }

    fetchBookingsAndVenues()
  }, [currentUser, getToken])

  /**
   * Handle cancelling a booking.
   *
   * @param {string} bookingId - Booking ID
   */
  const handleCancelBooking = async (bookingId) => {
    if (window.confirm('Are you sure you want to cancel your booking? This action cannot be undone.')) {
      try {
        const token = await getToken()
        const response = await fetch(`/api/bookings/cancel/${bookingId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.message || 'Failed to cancel booking')
        }
        setBookings(prevBookings => ({
          active: prevBookings.active.filter(booking => booking._id !== bookingId),
          past: prevBookings.past
        }))
        alert('Booking cancelled successfully')
      } catch (error) {
        console.error('Error cancelling booking:', error)
        alert('Failed to cancel booking: ' + error.message)
      }
    }
  }

  /**
   * Handle manage venue button click event.
   *
   * @param {string} venueId - Venue ID
   */
  const handleManageVenue = (venueId) => {
    navigate(`/manage-venue/${venueId}`)
  }

  return (
    <div className="Dashboard">
      <Header />
      <div className="dashboard-content">
        <h1>Dashboard</h1>
        {currentUser?.username && (
          <p className="user-welcome">Logged in as {currentUser.username}</p>
        )}

        <div className="your-venues">
          <h2>Your Venues</h2>
          {venues.length > 0
            ? (
                venues.map(venue => (
              <button
                key={venue._id}
                onClick={() => handleManageVenue(venue._id)}
                className="manage-venues-button"
              >
                Manage: {venue.name}
              </button>
                ))
              )
            : (
            <p>You don't have any venues yet.</p>
              )}
        </div>

        <div className='booking-list'>
  <h2>Active Bookings</h2>
  <div className='booking-divider'></div>
  {bookings.active && bookings.active.length > 0
    ? (
        bookings.active.map(booking => (
      <div key={booking._id} className='booking-item'>
        <div className='booking-info'>
          <span>Sport: {booking.venue?.sport || 'Unknown Sport'}</span>
          <span>Venue: {booking.venue?.name || 'Unknown Venue'}</span>
          <span>Location: {booking.venue?.location?.name || 'Unknown Location'}</span>
        </div>
        <p className='booking-datetime'>
          {new Date(booking.timeSlot.start).toISOString().slice(0, 16).replace('T', ' ')} UTC - {new Date(booking.timeSlot.end).toISOString().slice(0, 16).replace('T', ' ')}
        </p>
        <p>Players: {booking.players}</p>
        <button onClick={() => handleCancelBooking(booking._id)} className='cancel-booking-button'>Cancel Booking</button>
      </div>
        ))
      )
    : (
    <p>No active bookings found.</p>
      )}

  <h2>Past Bookings</h2>
  <div className='booking-divider'></div>
  {bookings.past && bookings.past.length > 0
    ? (
        bookings.past.map(booking => (
      <div key={booking._id} className='booking-item past-booking'>
        <div className='booking-info'>
          <span>Sport: {booking.venue?.sport || 'Unknown Sport'}</span>
          <span>Venue: {booking.venue?.name || 'Unknown Venue'}</span>
          <span>Location: {booking.venue?.location?.name || 'Unknown Location'}</span>
        </div>
        <p className='booking-datetime'>
          {new Date(booking.timeSlot.start).toISOString().slice(0, 16).replace('T', ' ')} - {new Date(booking.timeSlot.end).toISOString().slice(0, 16).replace('T', ' ')}
        </p>
        <p>Players: {booking.players}</p>
      </div>
        ))
      )
    : (
    <p>No past bookings found.</p>
      )}
</div>

      </div>
      <Footer />
    </div>
  )
}

export default Dashboard
