import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './AddVenue.css'
import sportConfig from '../configs/sportConfig'

/**
 * AddVenue is a React component that renders a form for users to add a new venue.
 * The form includes fields for venue details such as name, location, sport, resources,
 * and available time slots. The component also handles user authentication, venue data submission,
 * and time slot management.
 *
 * @return {JSX.Element} AddVenue component
 */
function AddVenue () {
  const [venueName, setVenueName] = useState('')
  const [location, setLocation] = useState('')
  const [resources, setResources] = useState([])
  const [selectedSport, setSelectedSport] = useState('')
  const [availableTimes, setAvailableTimes] = useState([])
  const [newTimeSlot, setNewTimeSlot] = useState({ date: '', startTime: '', endTime: '' })
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [intervalMinutes, setIntervalMinutes] = useState(60)
  const [showAddTimeSlot, setShowAddTimeSlot] = useState(false)
  const [showGenerateTimeSlots, setShowGenerateTimeSlots] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const { isAuthenticated, login, getToken } = useAuth()

  /**
   * Handles the process of adding a new venue.
   * This function checks if the user is authenticated, gathers the venue data,
   * sends a POST request to the server to add the venue, and handles the response.
   * On success, the form is reset and the user is redirected to the dashboard.
   */
  const handleAddVenue = async () => {
    if (!isAuthenticated) {
      alert('Please log in to add a venue')
      return
    }

    // Use the already converted UTC times in `availableTimes`
    const venueData = {
      name: venueName,
      location,
      sport: selectedSport,
      resources,
      availableTimes
    }

    try {
      const token = await getToken()
      const response = await fetch('/api/venues/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(venueData)
      })

      if (response.ok) {
        alert('Venue added successfully!')
        resetForm()
        navigate('/dashboard')
      } else {
        const error = await response.text()
        throw new Error(error)
      }
    } catch (error) {
      console.error('Error adding venue:', error)
      alert('Failed to add venue: ' + error.message)
    }
  }

  /**
   * Resets the form fields to their initial values.
   */
  const resetForm = () => {
    setVenueName('')
    setLocation('')
    setResources([])
    setSelectedSport('')
    setAvailableTimes([])
    setNewTimeSlot({ date: '', startTime: '', endTime: '' })
    setStartDate('')
    setEndDate('')
    setStartTime('')
    setEndTime('')
    setIntervalMinutes(60)
    setShowAddTimeSlot(false)
    setShowGenerateTimeSlots(false)
  }

  /**
   * Handles the process of logging in a user.
   */
  const handleLogin = async () => {
    if (!username || !password) {
      alert('Username and password are required')
      return
    }
    try {
      await login({ username, password })
      console.log('Login successful')
    } catch (error) {
      console.error('Login failed:', error)
      alert('Login failed: Please check your credentials and try again.')
    }
  }

  /**
   * Redirects the user to the signup page.
   */
  const handleSignup = () => {
    navigate('/signup')
  }

  /**
   * Generates time slots based on the user's input for start date, end date, start time, end time, and interval.
   */
  const handleGenerateTimeSlots = () => {
    if (!startDate || !endDate || !startTime || !endTime || !intervalMinutes) {
      alert('Please enter start and end dates, start and end times, and interval minutes for generating slots.')
      return
    }

    const timeSlots = []
    let currentDate = new Date(startDate)
    const endDateObj = new Date(endDate)

    while (currentDate <= endDateObj) {
      const currentStart = new Date(`${currentDate.toISOString().split('T')[0]}T${startTime}:00`)
      const currentEnd = new Date(`${currentDate.toISOString().split('T')[0]}T${endTime}:00`)

      let currentTime = new Date(currentStart)

      while (currentTime < currentEnd) {
        const nextTime = new Date(currentTime)
        nextTime.setMinutes(currentTime.getMinutes() + parseInt(intervalMinutes))

        timeSlots.push({
          date: currentDate.toISOString().split('T')[0],
          startTime: currentTime.toTimeString().substring(0, 5),
          endTime: nextTime.toTimeString().substring(0, 5)
        })

        currentTime = nextTime
      }

      // Move to the next day
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    }

    setAvailableTimes([...availableTimes, ...timeSlots])
  }

  /**
   * Adds a new time slot to the list of available time slots.
   */
  const handleAddTimeSlot = () => {
    if (!newTimeSlot.date || !newTimeSlot.startTime || !newTimeSlot.endTime) {
      alert('Please fill out all fields for the new time slot')
      return
    }

    // Save the time without converting to UTC
    const start = `${newTimeSlot.date}T${newTimeSlot.startTime}:00`
    const end = `${newTimeSlot.date}T${newTimeSlot.endTime}:00`

    console.log('Adding Time Slot:', { start, end })

    const newSlot = {
      date: newTimeSlot.date,
      startTime: newTimeSlot.startTime,
      endTime: newTimeSlot.endTime,
      start, // Store as is
      end
    }

    setAvailableTimes([...availableTimes, newSlot])
    setNewTimeSlot({ date: '', startTime: '', endTime: '' })
  }

  /**
   * Removes a time slot from the list of available time slots.
   *
   * @param {number} index - The index of the time slot to remove
   */
  const handleRemoveTimeSlot = (index) => {
    const updatedSlots = availableTimes.filter((_, i) => i !== index)
    setAvailableTimes(updatedSlots)
  }

  /**
   * Toggles the visibility of the Add Single Time Slot form.
   */
  const toggleAddTimeSlot = () => {
    setShowAddTimeSlot(!showAddTimeSlot)
  }

  /**
   * Toggles the visibility of the Generate Time Slots form.
   */
  const toggleShowGenerateTimeSlots = () => {
    setShowGenerateTimeSlots(!showGenerateTimeSlots)
  }

  return (
    <div className="AddVenue">
      <Header />
      <div className="manage-venue-content">
        <h1>Add Your Venue</h1>

        {isAuthenticated
          ? (
            <>
              <select value={selectedSport} onChange={e => setSelectedSport(e.target.value)} className="sport-dropdown">
                <option value="">Select sport...</option>
                {Object.keys(sportConfig).map(sport => (
                  <option key={sport} value={sport}>{sport}</option>
                ))}
              </select>

              <input type="text" placeholder="Venue Name" value={venueName} onChange={e => setVenueName(e.target.value)} />
              <input type="text" placeholder="Location" value={location} onChange={e => setLocation(e.target.value)} />

              <div className="button-group">
                <button onClick={toggleShowGenerateTimeSlots} className="center-button">
                  {showGenerateTimeSlots ? 'Hide Generate Time Slots' : 'Generate Time Slots'}
                </button>
                <button onClick={toggleAddTimeSlot} className="center-button">
                  {showAddTimeSlot ? 'Hide Add Single Time Slot' : 'Add Single Time Slot'}
                </button>
              </div>

              {showGenerateTimeSlots && (
                <div className="generate-time-slots">
                  <label className="generate-label">Generate Time Slots</label>
                  <div className="input-group">
                    <label>Start Date:</label>
                    <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                  </div>
                  <div className="input-group">
                    <label>End Date:</label>
                    <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                  </div>
                  <div className="input-group">
                    <label>Start Time:</label>
                    <input type="time" value={startTime} onChange={e => setStartTime(e.target.value)} />
                  </div>
                  <div className="input-group">
                    <label>End Time:</label>
                    <input type="time" value={endTime} onChange={e => setEndTime(e.target.value)} />
                  </div>
                  <div className="input-group">
                    <label>Interval (minutes):</label>
                    <input type="number" value={intervalMinutes} onChange={e => setIntervalMinutes(e.target.value)} />
                  </div>
                  <button onClick={handleGenerateTimeSlots} className="center-button">Generate Time Slots</button>
                </div>
              )}

              {showAddTimeSlot && (
                <div className="add-time-slot">
                  <h4>Add New Time Slot</h4>
                  <input
                    type="date"
                    value={newTimeSlot.date}
                    onChange={(e) => setNewTimeSlot({ ...newTimeSlot, date: e.target.value })}
                    placeholder="Date"
                  />
                  <input
                    type="time"
                    value={newTimeSlot.startTime}
                    onChange={(e) => setNewTimeSlot({ ...newTimeSlot, startTime: e.target.value })}
                    placeholder="Start Time"
                  />
                  <input
                    type="time"
                    value={newTimeSlot.endTime}
                    onChange={(e) => setNewTimeSlot({ ...newTimeSlot, endTime: e.target.value })}
                    placeholder="End Time"
                  />
                  <button onClick={handleAddTimeSlot} className="center-button">Add Time Slot</button>
                </div>
              )}

              <button onClick={handleAddVenue} className="center-button">Add Venue</button>

              <div className="time-slots">
                <h4>Time Slots</h4>
                {availableTimes.length > 0
                  ? (
                      availableTimes.map((timeSlot, index) => (
                      <div key={index} className="time-slot">
                        <p>{timeSlot.date} - {timeSlot.startTime} to {timeSlot.endTime}</p>
                        <button onClick={() => handleRemoveTimeSlot(index)}>Remove</button>
                      </div>
                      ))
                    )
                  : (
                    <div className="add-venue-time-slot no-time-slots">
                      <p>No time slots available</p>
                    </div>
                    )}
              </div>
            </>
            )
          : (
            <div className="login-venue-form">
              <p className="login-booking-prompt">
                <span className="login-message-booking">You need to log in to add a venue.</span><br />
                <span style={{ fontStyle: 'italic' }}>
                  Don't have an account?{' '}
                  <span className="signup-link" onClick={handleSignup}>
                    Sign up here!
                  </span>
                </span>
              </p>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="input-field-username-booking"
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input-field-password-booking"
              />
              <button onClick={handleLogin} className="login-button-booking">Log In</button>
            </div>
            )}
      </div>
      <Footer />
    </div>
  )
}

export default AddVenue
