import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './ManageVenue.css'

/**
 * ManageVenue component to manage a venue's time slots.
 *
 * @return {JSX.Element} JSX element
 */
function ManageVenue () {
  const { venueId } = useParams()
  const navigate = useNavigate()
  const { currentUser, getToken } = useAuth()
  const [venueDetails, setVenueDetails] = useState(null)
  const [newTimeSlot, setNewTimeSlot] = useState({ start: '', end: '' })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [editMode, setEditMode] = useState(null)
  const [availableTimes, setAvailableTimes] = useState([])
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [intervalMinutes, setIntervalMinutes] = useState(60)
  const [showGeneratedTimeSlots, setShowGeneratedTimeSlots] = useState(false)
  const [showAddTimeSlot, setShowAddTimeSlot] = useState(false)
  const [showGenerateTimeSlots, setShowGenerateTimeSlots] = useState(false)

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [currentUser, navigate])

  /**
   * Fetch venue details and listen for time slot updates.
   */
  useEffect(() => {
    /**
     * Fetch venue details from the API.
     */
    const fetchVenueDetails = async () => {
      setLoading(true)
      setError('')
      try {
        const token = await getToken()
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/details/${venueId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        if (!response.ok) {
          throw new Error('Failed to fetch venue details')
        }
        const data = await response.json()

        console.log('Fetched time slots:', data.timeSlots)

        // Process the fetched time slots
        setVenueDetails({
          ...data,
          timeSlots: data.timeSlots.map(slot => ({
            ...slot,
            start: new Date(slot.start),
            end: new Date(slot.end)
          }))
        })
      } catch (error) {
        setError('Error fetching venue details')
        console.error('Error fetching venue details:', error)
        navigate('/error')
      } finally {
        setLoading(false)
      }
    }

    fetchVenueDetails()

    const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/api/sse`)

    /**
     * Listen for time slot updates from the server.
     *
     * @param {MessageEvent} event - Message event
     */
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.venueId === venueId) {
        if (data.type === 'timeslot-removed') {
          setVenueDetails(prevDetails => ({
            ...prevDetails,
            timeSlots: prevDetails.timeSlots
              .filter(slot => slot._id !== data.timeSlotId)
              .sort((a, b) => new Date(a.start) - new Date(b.start))
          }))
        } else if (data.type === 'timeslot-updated') {
          const updatedTimeSlot = {
            _id: data.timeSlotId,
            start: new Date(data.newStart),
            end: new Date(data.newEnd)
          }
          setVenueDetails(prevDetails => ({
            ...prevDetails,
            timeSlots: prevDetails.timeSlots
              .map(slot => slot._id === data.timeSlotId ? updatedTimeSlot : slot)
              .sort((a, b) => new Date(a.start) - new Date(b.start))
          }))
        } else if (data.type === 'timeslot-added') {
          const newTimeSlot = {
            _id: data.timeSlotId,
            start: new Date(data.newStart),
            end: new Date(data.newEnd)
          }

          setVenueDetails(prevDetails => {
            const existingTimeSlots = prevDetails.timeSlots || []
            const updatedTimeSlots = existingTimeSlots.some(slot => (
              new Date(slot.start).getTime() === newTimeSlot.start.getTime() &&
              new Date(slot.end).getTime() === newTimeSlot.end.getTime()
            ))
              ? existingTimeSlots
              : [...existingTimeSlots, newTimeSlot]

            return {
              ...prevDetails,
              timeSlots: updatedTimeSlots.sort((a, b) => new Date(a.start) - new Date(b.start))
            }
          })
        }
      }
    }

    /**
     * Handle EventSource errors.
     *
     * @param {Event} error - Error event
     */
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
    }

    return () => {
      eventSource.close()
    }
  }, [venueId, navigate, getToken])

  /**
   * Add a new time slot to the venue.
   */
  const handleAddTimeSlot = async () => {
    setLoading(true)
    setError('')

    try {
      const token = await getToken()

      // Get the local time from the datetime-local input
      const localStart = new Date(newTimeSlot.start)
      const localEnd = new Date(newTimeSlot.end)

      // Log the local times
      console.log('Local time slot before conversion to UTC:', {
        start: localStart.toString(),
        end: localEnd.toString()
      })

      // Adjust the local time to UTC by manually applying the offset
      const utcStart = new Date(localStart.getTime() - localStart.getTimezoneOffset() * 60000).toISOString()
      const utcEnd = new Date(localEnd.getTime() - localEnd.getTimezoneOffset() * 60000).toISOString()

      // Send the adjusted UTC time to the server
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/timeslot/add`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ venueId, start: utcStart, end: utcEnd })
      })

      if (response.ok) {
        const result = await response.json()
        console.log('Server response:', result)

        const addedSlot = result.timeSlot || result.venue.timeSlots?.slice(-1)[0]

        if (!addedSlot || !addedSlot._id) {
          console.error('Error: No valid time slot ID returned from the server')
          return
        }

        setVenueDetails(prevDetails => {
          const existingTimeSlots = prevDetails.timeSlots || []

          // Ensure the new slot is not already in the list
          const updatedTimeSlots = existingTimeSlots.some(slot => slot._id === addedSlot._id)
            ? existingTimeSlots
            : [...existingTimeSlots, addedSlot].sort((a, b) => new Date(a.start) - new Date(b.start))

          return {
            ...prevDetails,
            timeSlots: updatedTimeSlots
          }
        })

        setNewTimeSlot({ start: '', end: '' })
        alert('Time slot added successfully')
      } else {
        const errorData = await response.json()
        console.error('Server Error:', errorData)
        throw new Error(errorData.message || 'Failed to add time slot')
      }
    } catch (error) {
      alert('Failed to add time slot: ' + error.message)
      console.error('Error adding time slot:', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Remove a time slot from the venue.
   *
   * @param {string} timeSlotId - Time slot ID
   */
  const handleRemoveTimeSlot = async (timeSlotId) => {
    if (!timeSlotId) {
      console.error('Error: timeSlotId is undefined')
      return
    }
    setLoading(true)
    setError('')
    try {
      const token = await getToken()
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/timeslot/remove`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ venueId, timeSlotId })
      })

      if (response.ok) {
        // Immediately update the state to remove the time slot
        setVenueDetails(prevDetails => ({
          ...prevDetails,
          timeSlots: prevDetails.timeSlots
            .filter(slot => slot._id !== timeSlotId)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
        }))
        alert('Time slot removed successfully')
      } else {
        const errorData = await response.json()
        throw new Error(errorData.message || 'Failed to remove time slot')
      }
    } catch (error) {
      alert('Failed to remove time slot: ' + error.message)
      console.error('Error removing time slot:', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Update a time slot with new start and end times.
   *
   * @param {string} timeSlotId - Time slot ID
   */
  const handleUpdateTimeSlot = async (timeSlotId) => {
    setLoading(true)
    setError('')
    try {
      const token = await getToken()
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/timeslot/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ venueId, timeSlotId, newStart: editMode.start, newEnd: editMode.end })
      })

      if (response.ok) {
        const updatedTimeSlot = {
          _id: timeSlotId,
          start: new Date(editMode.start),
          end: new Date(editMode.end)
        }

        setVenueDetails(prevDetails => ({
          ...prevDetails,
          timeSlots: prevDetails.timeSlots
            .map(slot => slot._id === timeSlotId ? updatedTimeSlot : slot)
            .sort((a, b) => new Date(a.start) - new Date(b.start))
        }))

        setEditMode(null)
        alert('Time slot updated successfully')
      } else {
        const errorData = await response.json()
        console.error('Server Error:', errorData)
        throw new Error(errorData.message || 'Failed to update time slot')
      }
    } catch (error) {
      alert('Failed to update time slot: ' + error.message)
      console.error('Error updating time slot:', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Delete the venue.
   */
  const handleDeleteVenue = async () => {
    if (window.confirm('Are you sure you want to delete this venue? This action cannot be undone.')) {
      setLoading(true)
      setError('')
      try {
        const token = await getToken()
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/${venueId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })

        if (response.ok) {
          alert('Venue deleted successfully')
          navigate('/dashboard')
        } else {
          const errorData = await response.json()
          throw new Error(errorData.message || 'Failed to delete venue')
        }
      } catch (error) {
        alert('Failed to delete venue: ' + error.message)
        console.error('Error deleting venue:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  /**
   * Generate time slots based on the start and end dates, start and end times, and interval minutes.
   */
  const handleGenerateTimeSlots = () => {
    if (!startDate || !endDate || !startTime || !endTime || !intervalMinutes) {
      alert('Please enter start and end dates, start and end times, and interval minutes for generating slots')
      return
    }

    const newTimeSlots = []
    const currentDate = new Date(startDate)

    while (currentDate.getTime() <= new Date(endDate).getTime()) { // Compare timestamps directly
      // Convert current start and end times to local time
      const currentStartLocal = new Date(`${currentDate.toISOString().split('T')[0]}T${startTime}:00`)
      const currentEndLocal = new Date(`${currentDate.toISOString().split('T')[0]}T${endTime}:00`)

      // Adjust the local times to UTC by manually applying the offset
      const currentStart = new Date(currentStartLocal.getTime() - currentStartLocal.getTimezoneOffset() * 60000)
      const currentEnd = new Date(currentEndLocal.getTime() - currentEndLocal.getTimezoneOffset() * 60000)

      let currentTime = new Date(currentStart)

      while (currentTime < currentEnd) {
        const nextTime = new Date(currentTime)
        nextTime.setMinutes(currentTime.getMinutes() + parseInt(intervalMinutes))

        if (nextTime > currentEnd) break

        const newSlot = {
          id: `${currentDate.toISOString().split('T')[0]}-${currentTime.toISOString().substring(11, 16)}-${nextTime.toISOString().substring(11, 16)}`,
          date: currentDate.toISOString().split('T')[0],
          startTime: currentTime.toISOString().substring(11, 16),
          endTime: nextTime.toISOString().substring(11, 16)
        }

        const isDuplicateInCurrentRun = newTimeSlots.some(
          slot =>
            slot.date === newSlot.date &&
            slot.startTime === newSlot.startTime &&
            slot.endTime === newSlot.endTime
        )

        if (!isDuplicateInCurrentRun) {
          newTimeSlots.push(newSlot)
        }

        currentTime = nextTime
      }

      currentDate.setDate(currentDate.getDate() + 1)
    }

    setAvailableTimes([...availableTimes, ...newTimeSlots])
    setShowGeneratedTimeSlots(true)
  }

  /**
   * Remove all generated time slots.
   */
  const handleRemoveGeneratedTimeSlots = () => {
    setAvailableTimes([])
    setShowGeneratedTimeSlots(false)
  }

  /**
   * Remove a generated time slot.
   *
   * @param {string} id - Time slot ID
   */
  const handleRemoveGeneratedTimeSlot = (id) => {
    setAvailableTimes(prevTimes => prevTimes.filter(slot => slot.id !== id))
  }

  /**
   * Update the venue with the generated time slots.
   */
  const handleUpdateVenueWithGeneratedSlots = async () => {
    setLoading(true)
    setError('')
    try {
      const token = await getToken()

      const uniqueTimeSlots = availableTimes.filter(
        (slot, index, self) =>
          index === self.findIndex(
            t => new Date(`${t.date}T${t.startTime}:00`).getTime() === new Date(`${slot.date}T${slot.startTime}:00`).getTime() &&
              new Date(`${t.date}T${t.endTime}:00`).getTime() === new Date(`${slot.date}T${slot.endTime}:00`).getTime()
          )
      )

      const formattedTimeSlots = uniqueTimeSlots.map(slot => {
        const startLocal = new Date(`${slot.date}T${slot.startTime}:00`)
        const endLocal = new Date(`${slot.date}T${slot.endTime}:00`)

        // Adjust to UTC
        const utcStart = new Date(startLocal.getTime() - startLocal.getTimezoneOffset() * 60000).toISOString()
        const utcEnd = new Date(endLocal.getTime() - endLocal.getTimezoneOffset() * 60000).toISOString()

        return { start: utcStart, end: utcEnd }
      })

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/timeslots/add-multiple`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ venueId, timeSlots: formattedTimeSlots })
      })

      if (response.ok) {
        const result = await response.json()

        const savedTimeSlots = result.timeSlots

        if (!Array.isArray(savedTimeSlots)) {
          throw new Error('Invalid time slots returned from the server')
        }

        setVenueDetails(prevDetails => {
          const existingTimeSlots = prevDetails.timeSlots || []

          // Merge new time slots and ensure uniqueness
          const updatedTimeSlots = [...existingTimeSlots, ...savedTimeSlots].filter((slot, index, self) =>
            index === self.findIndex(t => t._id === slot._id)
          ).sort((a, b) => new Date(a.start) - new Date(b.start))

          return {
            ...prevDetails,
            timeSlots: updatedTimeSlots
          }
        })

        setAvailableTimes([])
        setShowGeneratedTimeSlots(false)
        alert('Venue updated with generated time slots successfully')
      } else {
        const errorData = await response.json()
        console.error('Server Error:', errorData)
        throw new Error(errorData.message || 'Failed to update venue with time slots')
      }
    } catch (error) {
      alert('Failed to update venue: ' + error.message)
      console.error('Error updating venue:', error)
    } finally {
      setLoading(false)
    }
  }

  /**
   * Toggle the display of the add time slot form.
   */
  const toggleAddTimeSlot = () => {
    setShowAddTimeSlot(!showAddTimeSlot)
  }

  /**
   * Toggle the display of the generated time slots.
   */
  const toggleShowGeneratedTimeSlots = () => {
    setShowGeneratedTimeSlots(!showGeneratedTimeSlots)
  }

  /**
   * Toggle the display of the generate time slots form.
   */
  const toggleShowGenerateTimeSlots = () => {
    setShowGenerateTimeSlots(!showGenerateTimeSlots)
  }

  /**
   * Handle the edit button click event.
   *
   * @param {object} slot - Time slot object
   */
  const handleEditClick = (slot) => {
    setEditMode({
      timeSlotId: slot._id,
      start: new Date(slot.start).toISOString().slice(0, -1),
      end: new Date(slot.end).toISOString().slice(0, -1)
    })
  }

  /**
   * Handle time slot change event.
   *
   * @param {number} index - Time slot index
   * @param {string} field - Field name
   * @param {string} value - Field value
   */
  const handleTimeSlotChange = (index, field, value) => {
    const updatedSlots = availableTimes.map((slot, i) =>
      i === index ? { ...slot, [field]: value } : slot
    )
    setAvailableTimes(updatedSlots)
  }

  return (
    <div className="ManageVenue">
      <Header />
      <div className="manage-venue-content">
        <h1>Manage Venue</h1>
        {loading && <p>Loading...</p>}
        {error && <p className="error-message">{error}</p>}
        {venueDetails
          ? (
          <div>
            <h2>{venueDetails.name}</h2>
            <h3>Location: {venueDetails.location.name}</h3>
            <h3>Sport: {venueDetails.sport}</h3>

            <div className="button-group">
              <button onClick={toggleShowGenerateTimeSlots} className="center-button">
                {showGenerateTimeSlots ? 'Hide Generate Time Slots' : 'Generate Time Slots'}
              </button>
              <button onClick={toggleAddTimeSlot} className="center-button">
                {showAddTimeSlot ? 'Hide Add Single Time Slot' : 'Add Single Time Slot'}
              </button>
              <button onClick={handleDeleteVenue} className="delete-venue-button" disabled={loading}>
                Delete Venue
              </button>
            </div>

            {showGenerateTimeSlots && (
              <div className="generate-time-slots">
                <label className="generate-label">Generate Time Slots</label>
                <div className="input-group">
                  <label>Start Date:</label>
                  <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                </div>
                <div className="input-group">
                  <label>End Date:</label>
                  <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                </div>
                <div className="input-group">
                  <label>Start Time:</label>
                  <input type="time" value={startTime} onChange={e => setStartTime(e.target.value)} />
                </div>
                <div className="input-group">
                  <label>End Time:</label>
                  <input type="time" value={endTime} onChange={e => setEndTime(e.target.value)} />
                </div>
                <div className="input-group">
                  <label>Interval (minutes):</label>
                  <input type="number" value={intervalMinutes} onChange={e => setIntervalMinutes(e.target.value)} />
                </div>
                <button onClick={handleGenerateTimeSlots} className="center-button">Generate Time Slots</button>
              </div>
            )
            }

            {showAddTimeSlot && (
              <div className="add-time-slot">
                <h4>Add New Time Slot</h4>
                <input
                  type="datetime-local"
                  value={newTimeSlot.start}
                  onChange={(e) => setNewTimeSlot({ ...newTimeSlot, start: e.target.value })}
                  placeholder="Start Time"
                />
                <input
                  type="datetime-local"
                  value={newTimeSlot.end}
                  onChange={(e) => setNewTimeSlot({ ...newTimeSlot, end: e.target.value })}
                  placeholder="End Time"
                />
                <button onClick={handleAddTimeSlot} disabled={loading}>Add Time Slot</button>
              </div>
            )
            }

            {availableTimes.length > 0 && (
              <div className="button-group-horizontal">
                <button onClick={toggleShowGeneratedTimeSlots} className="center-button">
                  {showGeneratedTimeSlots ? 'Hide Generated Time Slots' : 'View Generated Time Slots'}
                </button>
                <button onClick={handleUpdateVenueWithGeneratedSlots} className="update-venue-button">
                  Update Venue with Generated Time Slots
                </button>
                <button onClick={handleRemoveGeneratedTimeSlots} className="remove-button">
                  Remove Generated Time Slots
                </button>
              </div>
            )
            }
            {showGeneratedTimeSlots && availableTimes.map((timeSlot, index) => (
              <div key={timeSlot.id} className="time-slot">
                <input
                  type="date"
                  value={timeSlot.date}
                  onChange={e => handleTimeSlotChange(index, 'date', e.target.value)}
                />
                <input
                  type="time"
                  value={timeSlot.startTime}
                  onChange={e => handleTimeSlotChange(index, 'startTime', e.target.value)}
                />
                <input
                  type="time"
                  value={timeSlot.endTime}
                  onChange={e => handleTimeSlotChange(index, 'endTime', e.target.value)}
                />
                <button onClick={() => handleRemoveGeneratedTimeSlot(timeSlot.id)} disabled={loading}>Remove</button>
              </div>
            )
            )}

<div className="time-slots">
  <h4>Existing Time Slots</h4>
  {venueDetails.timeSlots && venueDetails.timeSlots.length > 0
    ? (
        venueDetails.timeSlots.map((slot, index) => {
          const uniqueKey = slot._id || `${slot.start}-${slot.end}-${index}`

          return (
            <div key={uniqueKey} className="time-slot">
              {editMode && editMode.timeSlotId === slot._id
                ? (
                  <>
                    <input
                      type="datetime-local"
                      value={new Date(editMode.start).toISOString().slice(0, 16)} // Display UTC directly
                      onChange={(e) => {
                        setEditMode({
                          ...editMode,
                          start: new Date(e.target.value).toISOString() // Assume the input is UTC
                        })
                      }}
                    />
                    <input
                      type="datetime-local"
                      value={new Date(editMode.end).toISOString().slice(0, 16)} // Display UTC directly
                      onChange={(e) => {
                        setEditMode({
                          ...editMode,
                          end: new Date(e.target.value).toISOString() // Assume the input is UTC
                        })
                      }}
                    />
                    <button
                      className="update-button"
                      onClick={() => handleUpdateTimeSlot(slot._id)}
                      disabled={loading}
                    >
                      Save
                    </button>
                    <button
                      className="update-button"
                      onClick={() => setEditMode(null)}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </>
                  )
                : (
                  <>
                    <p>
                      {slot.start ? new Date(slot.start).toLocaleString('en-GB', { timeZone: 'UTC' }) : 'Invalid Start Date'} -
                      {slot.end ? new Date(slot.end).toLocaleString('en-GB', { timeZone: 'UTC' }) : 'Invalid End Date'}
                    </p>
                    <button
                      onClick={() => {
                        console.log('Deleting time slot with ID:', slot._id)
                        handleRemoveTimeSlot(slot._id)
                      }}
                      disabled={loading}
                    >
                      Remove
                    </button>
                    <button
                      className="update-button"
                      onClick={() => handleEditClick(slot)}
                      disabled={loading}
                    >
                      Update
                    </button>
                  </>
                  )}
            </div>
          )
        })
      )
    : (
    <div className="time-slot no-time-slots">
      <p>No time slots available</p>
    </div>
      )}
</div>

</div>
            )
          : (
              !loading && <p>Loading venue details...</p>
            )}
</div>
<Footer />
</div>
  )
}

export default ManageVenue
