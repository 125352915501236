// Defines sport configurations.
const sportConfig = {
  badminton: {
    playerOptions: [2, 4],
    defaultPlayers: 2
  },
  'golf-simulator': {
    playerOptions: [1, 2, 3, 4],
    defaultPlayers: 1
  },
  tennis: {
    playerOptions: [2, 4],
    defaultPlayers: 2
  },
  padel: {
    playerOptions: [2, 4],
    defaultPlayers: 2
  },
  squash: {
    playerOptions: [2],
    defaultPlayers: 2
  },
  pickleball: {
    playerOptions: [2, 4],
    defaultPlayers: 2
  },
  volleyball: {
    playerOptions: [2, 4, 6, 8, 10, 12],
    defaultPlayers: 6
  },
  tabletennis: {
    playerOptions: [2, 4],
    defaultPlayers: 2
  }
}

export default sportConfig
