import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Home from './components/Home'
import Booking from './components/Booking'
import Login from './components/Login'
import Signup from './components/Signup'
import Dashboard from './components/Dashboard'
import AddVenue from './components/AddVenue'
import DetailedBooking from './components/DetailedBooking'
import ManageVenue from './components/ManageVenue'
import Contact from './components/Contact'
import MyAccount from './components/MyAccount'
import { AuthProvider } from './context/AuthContext'
import CookieConsent from './components/CookieConsent'

/**
 * App component to display the application.
 *
 * @return {JSX.Element} JSX element
 */
function App () {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/booking/:sport" element={<Booking />} />
          <Route path="/venue-details/:venueId" element={<DetailedBooking />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-venue" element={<AddVenue />} />
          <Route path="/manage-venue/:venueId" element={<ManageVenue />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/my-account" element={<MyAccount />} />
        </Routes>
        <CookieConsent />
      </Router>
    </AuthProvider>
  )
}

export default App
