import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import './Signup.css'

/**
 * Signup component to display the signup page.
 *
 * @return {JSX.Element} JSX element
 */
function Signup () {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  /**
   * Handle signup button click event.
   */
  const handleSignup = async () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

    // Password validation
    if (password.length < 10) {
      setErrorMessage('Password must be at least 10 characters long.')
      return
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      })

      if (response.ok) {
        console.log('Signup successful')
        navigate('/login')
      } else {
        const error = await response.json()
        setErrorMessage(error.message)
      }
    } catch (error) {
      console.error('Signup error:', error)
      setErrorMessage('Signup failed')
    }
  }

  return (
    <div className="Signup">
      <Header />
      <div className="signup-container">
        <h1>Sign Up</h1>
        <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
        {errorMessage && <p className="error">{errorMessage}</p>}
        <button onClick={handleSignup} className="signup-page-button">Sign Up</button>
        <p className="password-requirements" style={{ fontStyle: 'italic' }}>
          Password must be at least 10 characters long.
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default Signup
