import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import './Home.css'
import './Header.css'
import './Footer.css'

/**
 * Home component to display the home page.
 *
 * @return {JSX.Element} JSX element
 */
function Home () {
  const [sport, setSport] = useState('')
  const [showAd, setShowAd] = useState(true)
  const navigate = useNavigate()

  /**
   * Handle sport change event.
   *
   * @param {Object} event - Event object
   */
  const handleSportChange = (event) => {
    const selectedSport = event.target.value
    setSport(selectedSport)
    console.log('Selected sport:', event.target.value)
    setTimeout(() => console.log('Delayed sport state:', sport), 1000)
  }

  /**
   * Handle confirm button click event.
   */
  const handleConfirm = () => {
    console.log('Sport state when Confirm button is clicked:', sport)
    if (sport !== '') {
      console.log('Navigating to booking with sport:', sport)
      navigate(`/booking/${sport}`)
    } else {
      alert('Please select a sport before confirming!')
    }
  }

  /**
   * Handle venue click event.
   */
  const handleVenueClick = () => {
    navigate('/add-venue')
  }

  /**
   * Handle remove Ad button click event
   */
  const handleRemoveAd = () => {
    setShowAd(false)
  }

  return (
    <div className="Home">
      <Header />
      <div className="Home-content">
        <div className="main-content">
          <h1>Book Your Sport Time!</h1>
          <div className="selection-container">
            <select onChange={handleSportChange} value={sport} className="sport-dropdown">
              <option value="">Select...</option>
              <option value="badminton">Badminton</option>
              <option value="golf-simulator">Golf-Simulator</option>
              <option value="tennis">Tennis</option>
              <option value="padel">Padel</option>
              <option value="squash">Squash</option>
              <option value="pickleball">Pickleball</option>
              <option value="volleyball">Volleyball</option>
              <option value="tabletennis">Table Tennis</option>
            </select>
            <button onClick={handleConfirm} className="confirm-button-home">Confirm</button>
          </div>
        </div>
        {showAd && (
          <div className="ad-section">
            <div className="ad-content">
              <p>Contact us to show your Ad Here!</p>
              <button onClick={handleRemoveAd} className="remove-ad-button">Remove Ad</button>
            </div>
          </div>
        )}
      </div>
      <div className="venue-section">
        <p>Want to add your venue to the booking system?</p>
        <button onClick={handleVenueClick} className="venue-button">Click Here!</button>
      </div>
      <Footer />
    </div>
  )
}

export default Home
