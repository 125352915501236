import { Link } from 'react-router-dom'
import './Footer.css'

/**
 * Footer component to display the footer.
 *
 * @return {JSX.Element} JSX element
 */
function Footer () {
  return (
    <footer className="Footer">
      <p>&copy; SportBooker 2024</p>
      <Link to="/contact" className="Footer-link">Contact Us</Link>
    </footer>
  )
}

export default Footer
