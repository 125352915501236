import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import './Header.css'

/**
 * Header component to display the navigation bar.
 *
 * @return {JSX.Element} JSX element
 */
function Header () {
  const navigate = useNavigate()
  const { logout, isAuthenticated } = useAuth()

  /**
   * Navigate to the login page.
   */
  const handleLogin = () => {
    navigate('/login')
  }

  /**
   * Navigate to the home page.
   */
  const handleHome = () => {
    navigate('/')
  }

  /**
   * Navigate to the signup page.
   */
  const handleSignup = () => {
    navigate('/signup')
  }

  /**
   * Logout the user and navigate to the home page.
   */
  const handleLogout = () => {
    logout()
    navigate('/')
  }

  /**
   * Navigate to the dashboard page.
   */
  const handleDashboard = () => {
    navigate('/dashboard')
  }

  /**
   * Navigate to the my account page.
   */
  const handleMyAccount = () => {
    navigate('/my-account')
  }

  return (
    <header className="Home-header">
      <img src={require('../images/TennisBall_2.png')} alt="SportBooker" className="Home-icon" onClick={handleHome} />
      <h1 className="Home-title" onClick={handleHome}>Home</h1>
      {isAuthenticated
        ? (
        <>
          <button onClick={handleDashboard} className="dashboard-button-header">Dashboard</button>
          <button onClick={handleMyAccount} className="my-account-button-header">My Account</button>
          <button onClick={handleLogout} className="logout-button-header">Log Out</button>
        </>
          )
        : (
        <>
          <button onClick={handleLogin} className="login-button-header">Log In</button>
          <button onClick={handleSignup} className="signup-button-header">Sign Up</button>
        </>
          )}
    </header>
  )
}

export default Header
