import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './Booking.css'
import sportConfig from '../configs/sportConfig'

/**
 * Booking component to book a venue for a specific sport.
 *
 * @return {JSX.Element} JSX element
 */
function Booking () {
  const { isAuthenticated, login } = useAuth()
  const { sport } = useParams()
  const config = sportConfig[sport] || {}
  const [venues, setVenues] = useState([])
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState('')
  const navigate = useNavigate()
  const [venue, setVenue] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    if (sport) {
      const url = `/api/venues/locations?sport=${encodeURIComponent(sport)}`
      console.log('Fetching locations with URL:', url)
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then(data => {
          setLocations(data)
          console.log('Fetched locations:', data)
        })
        .catch(error => {
          console.error('Error fetching locations:', error)
        })
    }
  }, [sport, isAuthenticated])

  useEffect(() => {
    if (selectedLocation) {
      const url = `/api/venues/by-location?location=${encodeURIComponent(selectedLocation)}&sport=${encodeURIComponent(sport)}`
      console.log('Fetching venues with URL:', url)
      fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then(data => {
          setVenues(data)
          console.log('Fetched venues:', data)
        })
        .catch(error => {
          console.error('Error fetching venues:', error)
        })
    }
  }, [selectedLocation, sport, isAuthenticated])

  /**
   * Handle location change event.
   *
   * @param {Event} event - Event object
   */
  const handleLocationChange = event => {
    const selectedLoc = event.target.value
    setSelectedLocation(selectedLoc)
    console.log('Selected location:', selectedLoc)
  }

  /**
   * Handle venue change event.
   *
   * @param {Event} event - Event object
   */
  const handleVenueChange = event => {
    const selectedVenueId = event.target.value
    setVenue(selectedVenueId)
    console.log('Selected venue:', selectedVenueId)
  }

  /**
   * Handle confirm button click event.
   */
  const handleConfirm = () => {
    if (venue) {
      navigate(`/venue-details/${venue}`)
    } else {
      alert('Please select a venue before confirming!')
    }
  }

  /**
   * Handle login button click event.
   */
  const handleLogin = async () => {
    if (!username || !password) {
      alert('Username and password are required')
      return
    }
    try {
      await login({ username, password })
      console.log('Login successful')
    } catch (error) {
      console.error('Login failed:', error)
      alert('Login failed: Please check your credentials and try again.')
    }
  }

  /**
   * Handle signup link click event.
   */
  const handleSignup = () => {
    navigate('/signup')
  }

  return (
    <div className={`booking-container booking-${sport}`} style={{ backgroundImage: `url(${config.backgroundImage})` }}>
      <Header />
      <h1 className="booking-header">Book your {sport}</h1>
      {isAuthenticated
        ? (
        <div className="booking-form">
          <select onChange={handleLocationChange} value={selectedLocation} className="booking-select">
            <option value="">Select location...</option>
            {locations.map(loc => (
              <option key={loc._id} value={loc.name}>{loc.name}</option> // Use name as the value
            ))}
          </select>
          {selectedLocation && (
            <select onChange={handleVenueChange} value={venue} className="booking-select">
              <option value="">Select venue...</option>
              {venues.map(v => (
                <option key={v._id} value={v._id}>{v.name}</option>
              ))}
            </select>
          )}
          <button className="confirm-button" onClick={handleConfirm}>Confirm</button>
        </div>
          )
        : (
        <div>
          <p className="login-booking-prompt">
            <span className="login-message-booking">You need to log in before booking.</span><br />
            <span style={{ fontStyle: 'italic' }}>
              Don't have an account? <span className="signup-link" onClick={handleSignup}>Sign up here!</span>
            </span>
          </p>
          <input type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} className="input-field-username-booking" />
          <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} className="input-field-password-booking" />
          <button onClick={handleLogin} className="login-button-booking">Log In</button>
        </div>
          )}
      <Footer />
    </div>
  )
}

export default Booking
