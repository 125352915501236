import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './DetailedBooking.css'

/**
 * DetailedBooking component to book a venue for a specific sport.
 *
 * @return {JSX.Element} JSX element
 */
function DetailedBooking () {
  const { venueId } = useParams()
  const navigate = useNavigate()
  const { currentUser, getToken } = useAuth()
  const [venueDetails, setVenueDetails] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedTime, setSelectedTime] = useState('')
  const [players, setPlayers] = useState(2)
  const [playerEmails, setPlayerEmails] = useState([''])
  const [sendEmail, setSendEmail] = useState(false)

  useEffect(() => {
    /**
     * Fetch venue details from the API.
     */
    const fetchVenueDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/venues/details/${venueId}`)
        if (!response.ok) {
          if (response.status === 404) {
            alert('The venue you are trying to access no longer exists.')
            navigate('/') // Redirect to home
            return
          } else {
            throw new Error('Failed to fetch venue details')
          }
        }
        const data = await response.json()

        if (data.message === 'Venue not found') {
          alert('The venue you are trying to access no longer exists.')
          navigate('/') // Redirect to home
          return
        }

        setVenueDetails(data)
        console.log('Fetched venue details:', data)
      } catch (error) {
        console.error('Error fetching venue details:', error)
        navigate('/error')
      }
    }

    fetchVenueDetails()

    const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}/api/sse`)

    /**
     * Event listener for server-sent events.
     *
     * @param {Event} event - The event object
     */
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.venueId === venueId) {
        if (data.type === 'venue-deleted') {
          alert('The venue you are trying to access no longer exists.')
          navigate('/') // Redirect to home
        } else if (data.type === 'timeslot-removed') {
          setVenueDetails(prevDetails => ({
            ...prevDetails,
            timeSlots: prevDetails.timeSlots.filter(slot => slot._id !== data.timeSlotId)
          }))
          if (data.timeSlotId === selectedTime) {
            alert('The selected time slot has been removed. Please choose another time.')
            setSelectedTime('')
            setSelectedDate('')
            navigate('/select-date')
          }
        } else if (data.type === 'timeslot-updated') {
          const updatedTimeSlot = {
            _id: data.timeSlotId,
            start: new Date(data.newStart),
            end: new Date(data.newEnd)
          }
          setVenueDetails(prevDetails => ({
            ...prevDetails,
            timeSlots: prevDetails.timeSlots
              .map(slot => slot._id === data.timeSlotId ? updatedTimeSlot : slot)
              .sort((a, b) => new Date(a.start) - new Date(b.start))
          }))
        } else if (data.type === 'timeslot-added') {
          const newTimeSlot = {
            _id: data.timeSlotId,
            start: new Date(data.newStart),
            end: new Date(data.newEnd)
          }
          setVenueDetails(prevDetails => ({
            ...prevDetails,
            timeSlots: [...prevDetails.timeSlots, newTimeSlot].sort((a, b) => new Date(a.start) - new Date(b.start))
          }))
        }
      }
    }

    /**
     * Event listener for errors from server-sent events.
     *
     * @param {Event} error - The error event
     */
    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
    }

    return () => {
      eventSource.close()
    }
  }, [venueId, navigate])

  /**
   * Group time slots by date.
   *
   * @param {Array} timeSlots - Array of time slots
   * @return {object} Object with time slots grouped by date
   */
  const groupTimeSlotsByDate = (timeSlots) => {
    if (!timeSlots) return {}
    return timeSlots.reduce((acc, slot) => {
      const date = new Date(slot.start).toDateString()
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(slot)
      return acc
    }, {})
  }

  /**
   * Handle date change event.
   *
   * @param {Event} event - Event object
   */
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value)
    setSelectedTime('')
    console.log('Selected date:', event.target.value)
  }

  /**
   * Handle time change event.
   *
   * @param {Event} event - Event object
   */
  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value)
    console.log('Selected time slot:', event.target.value)
  }

  /**
   * Handle player change event.
   *
   * @param {Event} event - Event object
   */
  const handlePlayerChange = (event) => {
    const numPlayers = event.target.value
    setPlayers(numPlayers)

    const emails = Array(parseInt(numPlayers, 10)).fill('')
    setPlayerEmails(emails)
  }

  /**
   * Handle email change event.
   *
   * @param {number} index - Index of the player
   * @param {Event} event - Event object
   */
  const handleEmailChange = (index, event) => {
    const emails = [...playerEmails]
    emails[index] = event.target.value
    setPlayerEmails(emails)
  }

  /**
   * Handle send email change event.
   *
   * @param {Event} event - Event object
   */
  const handleSendEmailChange = (event) => {
    setSendEmail(event.target.checked)
    console.log('Send Email Checkbox Toggled:', event.target.checked)
  }

  /**
   * Confirm the booking.
   */
  const confirmBooking = async () => {
    console.log('Confirming booking:', { venueId, timeSlot: selectedTime, players, playerEmails, sendEmail })

    if (!currentUser || !currentUser.id) {
      console.error('User is not defined or does not have an id:', currentUser)
      alert('You need to be logged in to confirm a booking.')
      navigate('/login')
      return
    }

    try {
      const token = await getToken()

      const selectedTimeSlot = venueDetails.timeSlots.find(slot => slot._id === selectedTime)

      if (!selectedTimeSlot) {
        alert('Selected time slot is invalid.')
        return
      }

      const requestBody = {
        venueId,
        timeSlot: {
          start: selectedTimeSlot.start,
          end: selectedTimeSlot.end
        },
        players,
        userId: currentUser.id,
        playerEmails: playerEmails.filter(email => email && email.trim() !== ''),
        sendEmail
      }
      console.log('Request Body:', requestBody)

      const response = await fetch('/api/bookings/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(requestBody)
      })

      console.log('Response status:', response.status)

      if (response.ok) {
        alert('Booking confirmed')
        const updatedTimeSlots = venueDetails.timeSlots.filter(slot => slot._id !== selectedTime)
        setVenueDetails({ ...venueDetails, timeSlots: updatedTimeSlots })
        navigate('/dashboard')
      } else {
        const error = await response.text()
        throw new Error(error)
      }
    } catch (error) {
      console.error('Error confirming booking:', error)
      alert('Failed to confirm booking: ' + error.message)
    }
  }

  if (!venueDetails) {
    return <p className="loading-message">Loading venue details...</p>
  }

  const timeSlotsByDate = groupTimeSlotsByDate(venueDetails.timeSlots)

  // Sort the available dates in ascending order
  const availableDates = Object.keys(timeSlotsByDate).sort((a, b) => new Date(a) - new Date(b))

  return (
    <div className={`detailed-booking-container detailed-booking-${venueDetails.sport}`}>
      <Header />
      <h1 className="detailed-booking-header">Book at {venueDetails.name}</h1>
      <div>
        <div className="detailed-booking-form">
          <label>Choose a date:</label>
          <select onChange={handleDateChange} value={selectedDate} className="detailed-booking-select">
            {availableDates.length === 0
              ? (
              <option value="">No available dates</option>
                )
              : (
              <>
                <option value="">Select a date</option>
                {availableDates.map(date => (
                  <option key={date} value={date}>
                    {date}
                  </option>
                ))}
              </>
                )
            }
          </select>
        </div>
        {selectedDate && timeSlotsByDate[selectedDate] && timeSlotsByDate[selectedDate].length > 0
          ? (
  <div className="detailed-booking-form">
    <label>Choose a time slot:</label>
    <select onChange={handleTimeChange} value={selectedTime} className="detailed-booking-select">
      <option value="">Select a time slot</option>
      {timeSlotsByDate[selectedDate].map(slot => (
        <option key={slot._id} value={slot._id}>
          {new Date(slot.start).toISOString().slice(11, 16)} - {new Date(slot.end).toISOString().slice(11, 16)}
        </option>
      ))}
    </select>
  </div>
            )
          : (
              selectedDate && (
    <p>No available time slots for the selected date. Please choose another date.</p>
              )
            )
}

        <div className="detailed-booking-form">
          <label>Number of Players:</label>
          <input type="number" value={players} onChange={handlePlayerChange} min="1" max="4" className="detailed-booking-input" />
        </div>
        {Array.from({ length: players }, (_, index) => (
          <div key={index} className="detailed-booking-form">
            <label>Player {index + 1} Email:</label>
            <input
              type="email"
              value={playerEmails[index] || ''}
              onChange={(event) => handleEmailChange(index, event)}
              className="detailed-booking-input"
              placeholder="Optional"
            />
          </div>
        )
        )
        }
        <div className="detailed-booking-form checkbox-container">
          <input
            type="checkbox"
            checked={sendEmail}
            onChange={handleSendEmailChange}
            className="styled-checkbox"
          />
          <span className="checkbox-label">Send confirmation email</span>
        </div>
        <div className="button-container">
          <button onClick={confirmBooking} className="detailed-booking-button">Confirm Booking</button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DetailedBooking
