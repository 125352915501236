import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import AuthService from '../services/AuthService'

const AuthContext = createContext({})

/**
 * Provider component to wrap the application and provide authentication context.
 *
 * @param {object} props Component props
 * @param {React.ReactNode} props.children Child components
 * @return {JSX.Element} JSX element
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser())
  const [token, setToken] = useState(AuthService.getToken())

  useEffect(() => {
    /**
     * Event listener to handle session changes in the application.
     */
    const handleSessionChange = () => {
      const user = AuthService.getCurrentUser()
      const token = AuthService.getToken()
      setCurrentUser(user)
      setToken(token)
      console.log('Session changed, current user:', user)
      console.log('Session changed, token:', token)
    }

    window.addEventListener('storage', handleSessionChange)

    return () => {
      window.removeEventListener('storage', handleSessionChange)
    }
  }, [])

  /**
   * Login user with the provided credentials.
   *
   * @param {object} credentials User credentials
   * @param {string} credentials.username User username
   * @param {string} credentials.password User password
   * @return {Promise<boolean>} Promise that resolves to a boolean indicating success
   */
  const login = async (credentials) => {
    try {
      const { user, token } = await AuthService.login(credentials)
      setCurrentUser(user)
      setToken(token)
      console.log('Login successful, user:', user)
      console.log('Login successful, token:', token)
      return true
    } catch (error) {
      console.error('Login failed:', error)
      return false
    }
  }

  /**
   * Logout the current user.
   */
  const logout = () => {
    AuthService.logout()
    setCurrentUser(null)
    setToken(null)
    console.log('Logout successful')
  }

  /**
   * Delete the current user's account.
   */
  const deleteUserAccount = async () => {
    try {
      await AuthService.deleteAccount(currentUser.id)
      logout()
      console.log('Account deletion successful')
    } catch (error) {
      console.error('Account deletion failed:', error)
      throw error
    }
  }

  /**
   * Get the current user's token.
   *
   * @return {string} User token
   */
  const getToken = () => token

  return (
    <AuthContext.Provider value={{ currentUser, isAuthenticated: !!currentUser, login, logout, getToken, deleteUserAccount }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

/**
 * Hook to use the authentication context in components.
 *
 * @return {object} Authentication context
 */
export const useAuth = () => useContext(AuthContext)
