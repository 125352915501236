import Header from './Header'
import Footer from './Footer'
import './Contact.css'

/**
 * Contact component to display the contact page.
 *
 * @return {JSX.Element} JSX element
 */
function Contact () {
  return (
    <div className="Contact">
      <Header />
      <div className="contact-content">
        <h1>Contact Us</h1>
        <p>If you have any questions or need assistance, feel free to reach out to us:</p>
        <p>Email: <a href="mailto:lb224nj@student.lnu.se">lb224nj@student.lnu.se</a></p>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
