import { useState, useEffect } from 'react'
import './CookieConsent.css'

/**
 * Cookie consent component to display a message about cookie usage.
 *
 * @return {JSX.Element} JSX element
 */
function CookieConsent () {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent')
    if (!consentGiven) {
      setIsVisible(true)
    }
  }, [])

  /**
   * Handle accept button click event.
   */
  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'true')
    setIsVisible(false)
  }

  return (
    isVisible && (
      <div className="cookie-consent">
        <p>This site uses cookies. By staying on it, you agree to this use.</p>
        <button onClick={handleAccept}>Accept</button>
      </div>
    )
  )
}

export default CookieConsent
