import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import Header from './Header'
import Footer from './Footer'
import './MyAccount.css'

/**
 * MyAccount component to display the user account page.
 *
 * @return {JSX.Element} JSX element
 */
function MyAccount () {
  const navigate = useNavigate()
  const { currentUser, deleteUserAccount } = useAuth()
  const [error, setError] = useState('')

  useEffect(() => {
    if (!currentUser) {
      navigate('/login')
    }
  }, [currentUser, navigate])

  /**
   * Handle delete account button click event.
   */
  const handleDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This action cannot be undone.')) {
      try {
        await deleteUserAccount()
        alert('Your account has been deleted.')
        navigate('/')
      } catch (err) {
        setError('Failed to delete account. Please try again.')
      }
    }
  }

  return (
    <div className="MyAccount">
      <Header />
      <div className="my-account-content">
        <h1>My Account</h1>
        <p>Logged in as: {currentUser?.username}</p> {/* Display username */}
        {error && <p className="error-message">{error}</p>}
        <button onClick={handleDeleteAccount} className="delete-account-button">
          Delete My Account
        </button>
      </div>
      <Footer />
    </div>
  )
}

export default MyAccount
